<template>
    <div class="page-wrapper">
        <img
            src="@/assets/img/site-web.jpg"
            alt="Conception web"
            class="mobile-hero"
            data-inview="revealRight"
            data-delay="200"
        />
        <section class="service-wrap scroll-fix">
            <div class="left">
                <div class="data intro">
                    <h1 class="title">{{ $t('Conception web') }}</h1>
                    <p class="regular-text">
                        {{ $t('Créer un site web performant n’est pas un jeu d’enfant. Confiez votre projet à des professionnels') }}
                    </p>
                    <a href="#" class="cta" @click.prevent="showform"
                        ><p>{{ $t('Demander une soumission') }}</p></a
                    >
                </div>

                <div id="serv-1" class="data" data-image-index="0">
                    <p class="number">01</p>
                    <h2 class="title small">{{ $t('Conception de site web') }}</h2>
                    <p class="regular-text">
                        {{ $t('La présence de votre entreprise sur le web passe plus que jamais par la création d’un site internet. Pour être efficace et générer des revenus, votre site web doit placer l’intérêt de vos clients au coeur de sa stratégie. Nous sommes là pour vous aider.') }}
                    </p>
                </div>

                <div id="serv-2" class="data">
                    <p class="number">02</p>
                    <h2 class="title small">{{ $t('Site web vitrine') }}</h2>
                    <p class="regular-text">
                        {{ $t('Un site vitrine résume brièvement les activités de votre entreprise sans toutefois mettre de l’avant vos produits. Si vous avez simplement besoin d’un site web incluant quelques pages et que vous voulez qu’il soit en ligne rapidement, c’est votre meilleure option.') }}
                    </p>
                </div>

                <div id="serv-3" class="data" data-image-index="1">
                    <p class="number">03</p>
                    <h2 class="title small">{{ $t('Commerce en ligne') }}</h2>
                    <p class="regular-text">
                        {{ $t('La vente en ligne prend une place de plus en plus importante dans la vie des Québécois. Si vous avez des produits à vendre, votre inventaire doit être répertorié sur le web. Grâce à notre expertise, vous serez trouvé plus facilement et augmenterez vos ventes.') }}
                    </p>
                </div>

                <div id="serv-4" class="data">
                    <p class="number">04</p>
                    <h2 class="title small">{{ $t('Page de destination') }}</h2>
                    <p class="regular-text">
                        {{ $t('Si vous avez un besoin très précis à combler et que vous avez seulement besoin d’une page de destination optimisée selon votre objectif d’affaires, on peut vous aider. Notre sixième sens concernant les meilleures pratiques en design web vous sera utile.') }}
                    </p>
                </div>

                <div id="serv-5" class="data" data-image-index="2">
                    <p class="number">05</p>
                    <h2 class="title small">{{ $t('Conversions') }}</h2>
                    <p class="regular-text">
                        {{ $t('L’optimisation du taux de conversion (CRO) est une pratique qui consiste à analyser et améliorer les différents éléments d’une page dans le but d’améliorer sa performance. Pour y arriver, nous avons à notre disposition les meilleurs outils de l’industrie.') }}
                    </p>
                </div>

                <div id="serv-6" class="data">
                    <p class="number">06</p>
                    <h2 class="title small">{{ $t('Expérience utilisateur') }}</h2>
                    <p class="regular-text">
                        {{ $t('Notre réflexion stratégique et notre flair du design s’inspirent du comportement des utilisateurs (UX). C’est en décodant leurs habitudes, en analysant leurs intérêts et en identifiant leurs besoins qu’on arrive à des résultats à la hauteur de vos attentes.') }}
                    </p>
                </div>

                <div id="serv-7" class="data">
                    <p class="number">07</p>
                    <h2 class="title small">{{ $t('Maintenance de site web') }}</h2>
                    <p class="regular-text">
                        {{ $t('Parce qu’après la création d’un site, le travail ne fait que commencer. Vous aurez besoin de bras pour prendre en charge sa gestion. Faites équipe avec des experts de confiance qui vous accompagneront dans l’évolution et la croissance de vos affaires.') }}
                    </p>
                </div>

                <div id="serv-8" class="data">
                    <p class="number">08</p>
                    <h2 class="title small">{{ $t('Hébergement de site web') }}</h2>
                    <p class="regular-text">
                        {{ $t('Notre but est de vous simplifier la vie. Nous offrons un service clé en main qui inclut l’hébergement de votre site sur des serveurs locaux et performants. Inutile de vous casser la tête avec les éléments techniques. Avec elmire, dormez sur vos deux oreilles.') }}
                    </p>
                </div>
            </div>
            <div class="right fixed-floating-image">
                <img
                    class="float-img is-active"
                    src="@/assets/img/service-1.jpg"
                    alt=""
                    data-inview="revealRight"
                    data-delay="200"
                />
                <img class="float-img" src="@/assets/img/service-2.jpg" alt="" />
                <img class="float-img" src="@/assets/img/service-3.jpg" alt="" />
            </div>
        </section>

        <section class="autres-services" v-if="$route && $route.meta">
            <a :href="$route.meta.lang === 'fr' ? '/services/referencement' : '/en/services/seo'" class="box">
                <div class="bottom">
                    <h3 class="title">{{ $t('Référencement') }}</h3>
                    <img src="@/assets/img/arrow-right.svg" class="arrow" alt="Arrow right" />
                </div>
                <div class="overlay"></div>
                <img
                    src="@/assets/img/referencement-small.jpg"
                    class="main-img"
                    alt="Référencement"
                />
            </a>
            <a :href="$route.meta.lang === 'fr' ? '/services/publicite-en-ligne' : '/en/services/web-advertising'" class="box">
                <div class="bottom">
                    <h3 class="title">{{ $t('Publicité en ligne') }}</h3>
                    <img src="@/assets/img/arrow-right.svg" class="arrow" alt="Arrow right" />
                </div>
                <div class="overlay"></div>
                <img
                    src="@/assets/img/publicite-en-ligne-small.jpg"
                    class="main-img"
                    alt="Publicité en lignee"
                />
            </a>
        </section>

        <div class="overlay-form" :class="{ show: form }">
            <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="hideform" />
            <div class="form-wrap">
                <h3 class="title">{{ $t('Demander une soumission') }}</h3>
                <FormSoumission :hideform="hideform" />
            </div>
            <div class="opacity" @click.prevent="hideform"></div>
        </div>
    </div>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import FormSoumission from '@/components/FormSoumission'

gsap.registerPlugin(ScrollTrigger)

// Intro Animation Scripts
require('@/plugins/AnimationsLayer')

export default {
    name: 'Services',

    components: { FormSoumission },

    data() {
        return {
            form: false,
            isSending: false,
            formData: {},
        }
    },

    methods: {
        showform() {
            if (this.form) {
                this.form = false
            } else {
                this.form = true
            }
        },
        hideform() {
            this.form = false
        },
    },

    watch: {
        $route: {
            handler($route) {
                if ($route && $route.hash) {
                    setTimeout(() => {
                        const $zone = document.querySelector($route.hash.replace('a-', ''))
                        const topPos = $zone.getBoundingClientRect().top + window.scrollY - 200
                        // this changes the scrolling behavior to "smooth"
                        window.scroll({
                            top: topPos,
                            behavior: 'smooth',
                        })
                    }, 1175)
                }
            },
            immediate: true,
        },
    },

    mounted() {
        // const scrollFixHeight = document.querySelector('.scroll-fix').offsetHeight
        // const imageFixHeight = document.querySelector('.fixed-floating-image img').offsetHeight
        const floatImgs = [].slice.call(document.querySelectorAll('.float-img'))

        gsap.to('.scroll-fix', {
            scrollTrigger: {
                trigger: '.scroll-fix',
                start: 'top 91',
                end: 'bottom bottom',
                scrub: true,
                pin: '.fixed-floating-image',
                markers: false,
                anticipatePin: 1,
            },
            ease: 'none',
        })

        const loopArray = []
        loopArray.slice
            .call(document.querySelectorAll('[data-image-index]'))
            .forEach(function (elem) {
                gsap.to(elem, {
                    scrollTrigger: {
                        trigger: elem,
                        start: 'top center',
                        markers: false,
                        onEnter: () => {
                            floatImgs.forEach(img => {
                                img.classList.remove('is-active')
                            })
                            floatImgs[elem.getAttribute('data-image-index')].classList.add(
                                'is-active'
                            )
                        },
                        onEnterBack: () => {
                            floatImgs.forEach(img => {
                                img.classList.remove('is-active')
                            })
                            floatImgs[elem.getAttribute('data-image-index')].classList.add(
                                'is-active'
                            )
                        },
                    },
                    ease: 'none',
                })
            })
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/02-Tools/_variables.scss';

.mobile-hero {
    display: none;
    @media only screen and (max-width: 600px) {
        display: block;
    }
}

.service-wrap {
    width: 100%;
    position: relative;

    .left {
        position: relative;
        width: 50%;
        padding: 5vw 10vw;
        background-color: $color5;

        .data {
            margin-top: 15vw;

            &:last-child {
                padding-bottom: 15vw;
            }

            .number {
                color: $color1;
                font-weight: bold;
                font-size: 24px;
                margin-bottom: 20px;
                position: relative;
                z-index: 100;

                &:before {
                    content: '';
                    display: block;
                    width: 80px;
                    height: 5px;
                    background-color: $color3;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translate(0, -50%);
                    z-index: -1;
                    transition: all 0.2s ease;
                }
            }

            .title {
                margin-bottom: 20px;
            }

            &.intro {
                margin-top: 10vw;
                .title {
                    font-size: 30px;
                    margin-bottom: 30px;
                }
                .regular-text {
                    margin-bottom: 30px;
                }
                .cta {
                    &:hover {
                        &:before {
                            width: 240px;
                            height: 240px;
                        }
                    }
                }
            }
        }
    }

    .right {
        width: 50%;
        height: calc(100vh + 25px);
        position: absolute;
        top: -25px;
        right: 0;

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .float-img {
            display: block;
            width: 100%;
            height: 100vh;
            object-fit: cover;

            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            object-fit: cover;

            opacity: 0;

            transition: opacity 0.65s ease 0s;

            &.is-active {
                opacity: 1;
            }
        }
    }

    @media only screen and (max-width: 1000px) {
        .left {
            width: 70%;
        }
        .right {
            width: 30%;
        }
    }
    @media only screen and (max-width: 600px) {
        .left {
            width: 100%;
        }
        .right {
            display: none;
        }
    }
}

.autres-services {
    padding: 5vw;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    position: relative;
    z-index: 1000;

    .box {
        display: block;
        width: calc(50% - 20px);
        position: relative;
        overflow: hidden;

        .main-img {
            transform: scale(1, 1);
            position: relative;
            z-index: 1;
            transition: all 0.4s ease;
        }

        &:hover {
            .main-img {
                transform: scale(1.1, 1.1);
            }
        }

        .overlay {
            width: 100%;
            height: 70%;
            z-index: 10;
            position: absolute;
            left: 0;
            bottom: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
            opacity: 0.7;
        }

        .bottom {
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 100;
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 30px;

            .title {
                color: #fff;
            }

            .arrow {
                width: 30px;
            }
        }
    }

    @media only screen and (max-width: 800px) {
        padding: 10vw 5vw;
        flex-wrap: wrap;

        .box {
            width: 100%;
            margin-bottom: 5vw;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.overlay-form {
    position: relative;
    z-index: -1;
    transition: all 0.2s ease;

    &.show {
        z-index: 99999999;
        .form-wrap {
            transform: translate(-50%, -50%) scale(1, 1);
            opacity: 1;
        }
        .opacity {
            z-index: 1001;
            opacity: 0.8;
        }
        .close {
            display: block;
        }
    }

    .form-wrap {
        position: fixed;
        top: 50%;
        left: 50%;
        z-index: 1500;
        width: 550px;
        padding: 4vw;
        transform: translate(-50%, -50%) scale(0.8, 0.8);
        background-color: #fff;
        text-align: center;
        transition: all 0.2s ease;
        opacity: 0;
    }

    .title {
        margin-bottom: 20px;
    }

    .close {
        width: 30px;
        position: fixed;
        top: 25px;
        right: 25px;
        cursor: pointer;
        z-index: 1500;
        display: none;
        opacity: 1;
    }

    .opacity {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background-color: #000;
        opacity: 0;
        z-index: -1;
        transition: all 0.2s ease;
    }

    @media only screen and (max-width: 600px) {
        .form-wrap {
            width: 330px;
            padding: 8vw;
        }
    }
}
</style>
