<template>
    <form action="" method="post" accept-charset="UTF-8" @submit="sendForm($event)">
        <input type="hidden" name="action" value="contact-form/send" />

        <input
            type="text"
            :placeholder="$t('Prénom et nom')"
            name="fromName"
            v-model="formData.fromName"
            required
        />
        <input type="text" :placeholder="$t('Téléphone')" v-model="formData.telephone" required />
        <input
            type="email"
            :placeholder="$t('Courriel')"
            name="fromEmail"
            v-model="formData.fromEmail"
            required
        />

        <input type="text" :placeholder="$t('Entreprise')" v-model="formData.entreprise" required />

        <textarea
            :placeholder="$t('Message')"
            name="message[body]"
            v-model="formData.message"
            required
        ></textarea>

        <input type="submit" class="cta" :class="{ 'is-sending': isSending }" value="Envoyer" />
    </form>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'

import 'sweetalert2/src/sweetalert2.scss'

export default {
    name: 'FormSoumission',

    props: {
        hideform: { type: Function, required: true },
    },

    data() {
        return {
            isSending: false,
            formData: {},
        }
    },

    methods: {
        async sendForm(e) {
            e.preventDefault()

            if (this.isSending) {
                return
            }

            this.isSending = true

            this.formData['formName'] = 'Soumission'
            this.formData['sujet'] = 'Soumission'

            const formData = new FormData()
            formData.append('formName', this.formData.formName)
            formData.append('sujet', this.formData.sujet)
            formData.append('courriel', this.formData.fromEmail)
            formData.append('message', this.formData.message)
            formData.append('nom', this.formData.fromName)
            formData.append('entreprise', this.formData.entreprise)
            formData.append('telephone', this.formData.telephone)

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }

            await axios
                .post(
                    `${process.env.VUE_APP_SITE_BASE_URL}/api/send/contact-form`,
                    formData,
                    config
                )
                .then(res => {
                    this.formMessage = 'success'
                    this.formMessageStatus = res.status

                    Swal.fire({
                        text: 'Votre message a été envoyé avec succès.',
                        icon: 'success',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })

                    this.hideform()
                    this.formData = {}
                    this.formMessage = ''

                    setTimeout(() => {
                        this.isSending = false
                    }, 3000)
                })
                .catch(e => {
                    this.formMessage = e

                    setTimeout(() => {
                        this.hideform()
                        this.formData = {}
                        this.formMessage = ''
                        this.isSending = false
                    }, 2275)
                })
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/02-Tools/_variables.scss';
</style>
