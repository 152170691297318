import { render, staticRenderFns } from "./Referencement.vue?vue&type=template&id=00120e26&scoped=true&"
import script from "./Referencement.vue?vue&type=script&lang=js&"
export * from "./Referencement.vue?vue&type=script&lang=js&"
import style0 from "./Referencement.vue?vue&type=style&index=0&id=00120e26&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00120e26",
  null
  
)

export default component.exports