<template>
    <div class="page-wrapper">

        <section class="politique" v-if="$route.meta && $route.meta.lang === 'fr'">
            <h1 class="title">POLITIQUE DE CONFIDENTIALITÉ</h1>
            <p class="regular-text">
                elmire tient à vous informer de l’usage que nous faisons des renseignements que nous
                recueillons lorsque vous visitez notre site Web (ou encore nos plateformes de médias
                sociaux) ainsi que lorsque vous visitez les sites de nos compagnies affiliées
                faisant l’objet d’un hyperlien que nous autorisons (ci-après le « Site »). Par
                conséquent, la présente Politique de confidentialité (ci-après la « Politique »)
                définit la façon dont nous recueillons, utilisons, divulguons et traitons les
                renseignements que vous nous communiquez lorsque vous utilisez le Site.
            </p>
            <h2 class="title medium">RENSEIGNEMENTS PERSONNELS</h2>
            <h3 class="title small">
                1. Que signifient les « Renseignements personnels » (ci-après « RP ») et comment
                sont-ils collectés ?
            </h3>
            <p class="regular-text">
                Les RP sont des renseignements concernant une personne identifiable. C’est-à-dire,
                des informations qui peuvent être utilisées afin de vous identifier. Dans certaines
                circonstances bien particulières, soit lors de l'inscription à nos différents
                services de personnalisation, de courriels et de concours publicitaires en ligne,
                elmire demande que vous inscriviez vos nom, prénom, adresse, adresse de courriel et
                certains autres renseignements dans un formulaire à l'écran.<br /><br />Nos serveurs
                peuvent aussi recueillir l’adresse IP de votre ordinateur et le nom de votre
                fournisseur de service Internet. Dans la plupart des cas, ces renseignements ne sont
                pas des RP mais plutôt des Données de navigation, et nous vous renvoyons à la
                section « Données de navigation » ci-dessous pour mieux comprendre notre politique à
                ce sujet.
            </p>
            <h3 class="title small">2. Comment les RP sont-ils utilisés ?</h3>
            <p class="regular-text">
                La collecte des RP nous permet de pouvoir vous donner un service plus pertinent à
                vos besoins, améliorer le contenu, l’interface et les caractéristiques du Site en
                fonction de l’information recueillie, de vous joindre dans l'éventualité où vous
                gagneriez un prix et, si vous nous en avez signifié le désir, de vous faire
                parvenir, ponctuellement, de l'information par courriel. Ces données sont utilisées
                également à des fins de contrôle et visent l’amélioration de l’utilisation.
            </p>
            <h3 class="title small">3. Les RP sont-ils partagés avec des tiers ?</h3>
            <p class="regular-text">
                Nous ne transférons, ne vendons et ne divulguons pas vos RP de quelque manière que
                ce soit à une tierce partie, incluant à nos annonceurs, autres clients ou
                partenaires, sans avoir préalablement obtenu votre consentement. Nonobstant ce qui
                précède, vos RP peuvent être communiqués : (i) aux filiales et compagnies liées à
                elmire et (ii) aux compagnies tierces engagées par elmire afin de stocker ou traiter
                ces renseignements au nom et pour le compte de elmire selon des ententes
                contractuelles garantissant la confidentialité et la sécurité de ces RP. Veuillez
                noter que elmire peut utiliser les services de fournisseurs situés à l’extérieur du
                Canada. De ce fait, il est possible, dans de rares cas, que les lois en vigueur dans
                ces pays étrangers permettent la communication des RP à des autorités judiciaires,
                quasi-judiciaires ou gouvernementales.
            </p>
            <h3 class="title small">4. Recevons-nous des RP de tiers?</h3>
            <p class="regular-text">
                Lorsque vous utilisez l’authentification unique de nos partenaires de réseaux
                sociaux (soit Facebook, Twitter, Yahoo! et Google), nous pourrions recevoir votre
                adresse courriel, de même que les informations de votre profil public. Si vous
                souhaitez contrôler ces informations, vous devez configurer vos paramètres de vie
                privée sur les réseaux sociaux concernés.
            </p>
            <h3 class="title small">
                5. Comment et pendant combien de temps les RP sont-ils conservés ?
            </h3>
            <p class="regular-text">
                Les RP recueillis sont sauvegardés dans des serveurs inaccessibles au public et sont
                protégés au moyen de mesures de sécurité appropriées. Ainsi, soyez assurés que des
                mesures de sécurité sont mises en place pour protéger vos données contre la perte,
                le vol, la consultation, la copie, l’utilisation ou les modifications non
                autorisées.<br /><br />Nous ne conserverons les RP que dans la mesure où elles
                demeurent nécessaires ou pertinentes aux fins mentionnées dans la Politique ou
                conformément aux exigences de la loi.
            </p>
            <h3 class="title small">6. Quels sont les risques?</h3>
            <p class="regular-text">
                Nonobstant ce qui précède, et malgré notre bonne volonté et nos mesures mises en
                place, aucune transmission de données sur le Site ni sur Internet en général n’est
                totalement garantie ou sans risque. Nous ne pouvons ainsi garantir la sécurité
                absolue de vos RP et nous ne pouvons donc accepter toute responsabilité concernant
                les tiers qui pourraient en faire usage ou mauvais usage, une interception en cours
                de transmission par le biais de l’Internet ou faisant l’objet de piratage
                informatique ou de fraude. Soyez vigilants, notre Site peut contenir des liens vers
                des sites Web gérés par des tiers ; ces liens ne vous sont proposés que pour votre
                agrément. L'activation de ces liens vous fait quitter le Site. Sachez que le Site et
                elmire n'exercent aucun contrôle sur les sites Web des tiers et que le fait que ces
                liens soient répertoriés sur le Site n'engage en aucun cas la responsabilité de
                elmire. De plus, sachez que certains tiers peuvent recueillir vos données à notre
                insu, telle que votre adresse IP et vos données de navigation. Nous mettons tout en
                œuvre pour que cela ne se produise pas, mais certaines pratiques de tiers demeurent
                hors de notre contrôle.<br /><br />Par ailleurs, la diffusion volontaire de RP lors
                de sessions de chat par messagerie, sur un blogue ou sur toute page interactive où
                vous contribuez publiquement par la mise en ligne de contenu que vous générez n'est
                pas couverte par la présente Politique. La vigilance est de mise lorsque vous
                communiquez vos RP par ces moyens et nous vous prions de prendre connaissance de nos
                Conditions d’utilisation. elmire se dégage de toute responsabilité à cet égard. Nous
                vous conseillons, en tout temps, de demeurer prudent et attentif lorsque vous êtes
                en ligne, naviguez sur Internet et lorsque vous créez du contenu sur Internet par
                l’ajout, notamment, de commentaires.
            </p>
            <h3 class="title small">7. Pouvez-vous accéder à vos RP?</h3>
            <p class="regular-text">
                Oui, vous pouvez accéder aux RP vous concernant ou en demander la rectification en
                présentant une demande écrite à contact@elmire.ca. Nous serons en
                mesure de répondre à votre demande que dans le cas où nous avons un dossier
                personnalisé vous concernant (ce qui est le cas lorsque vous nous avez communiqué,
                ou que vous consentez à nous communiquer, des RP).
            </p>
            <h2 class="title medium">DONNÉES DE NAVIGATION</h2>
            <h3 class="title small">
                1. Que signifient les « Données de navigation », et comment sont-elles collectées ?
            </h3>
            <p class="regular-text">
                Les Données de navigation » sont des données anonymes concernant votre activité de
                navigation ou votre appareil. Par exemple, des données concernant votre adresse IP,
                votre historique de navigation, le contenu de ces pages, le nombre de clics que vous
                effectuez, votre type d’appareil, le site dont vous provenez et le site vers lequel
                vous allez en quittant le nôtre, votre lieu géographique imprécis, votre courriel
                haché, ainsi que les publicités et vidéos consultées sur le site.<br /><br />Les
                Données de navigation sont collectées en utilisant des fichiers témoins (« cookies
                »), soit des petits fichiers de données qui s’inscrivent sur le disque dur de vos
                appareils électroniques lorsque vous naviguez sur ce site. Ces fichiers témoins sont
                automatiquement transmis par le serveur de ce site vers le navigateur de votre
                appareil et sont archivés, par défaut, sur le disque dur de ce dernier.<br /><br />Les
                Données de navigation sont anonymes, de sorte que nous ne les utilisons pas dans le
                but de découvrir votre identité (par couplage ou autrement). Elles ne sont utilisées
                que pour les fins décrites ci-après.
            </p>
            <h3 class="title small">2. Qui collecte les Données de navigation ?</h3>
            <p class="regular-text">
                Les Données de navigation sont ou peuvent être collectées, en tout ou en partie, par
                (i) nous, soit elmire, ses filiales et sociétés liées (ci-après « nous ») et (ii)
                certaines sociétés avec lesquelles nous collaborons à des fins commerciales ou non
                commerciales (telles que nos partenaires publicitaires, partenaires de contenus,
                sociétés qui pourraient acquérir certains de nos actifs, fournisseurs de services,
                de solutions ou de plateformes technologiques reliés à ce site) (« Partenaires
                »).<br /><br />Les données que nous pouvons recueillir ainsi que nos Partenaires
                sont dites « non sensibles » et sont sous une forme dépersonnalisée. De plus, nos
                Partenaires sont tenus par la loi d’adhérer aux principes reconnus de protection des
                renseignements personnels et respecter leurs propres politiques en matière de
                protection de vie privée.
            </p>
            <h3 class="title small">3. Comment les Données de navigation sont-elles utilisées ?</h3>
            <p class="regular-text">
                Ces renseignements sont collectés afin de nous permettre :<br /><br />(a) de générer
                des données statistiques concernant notre niveau de lectorat, la performance et
                l’utilisation de nos sites et de leurs contenus et le genre de personne pour qui ils
                suscitent de l’intérêt;<br /><br />(b) d’améliorer votre expérience de navigation.
                Par exemple, les données sur votre navigateur [à savoir les fonctions de langue, le
                fuseau horaire et le module d’extensions] vous seront proposées par défaut lors de
                vos visites subséquentes sur ce site afin que vous n’ayez pas à saisir, de façon
                répétitive, certains paramètres qui vous sont propres ; et<br /><br />(c) de vous
                fournir des publicités en lien avec vos intérêts, comme par exemple des publicités
                en lien avec les sites et contenus que vous avez consultés.
            </p>
            <h3 class="title small">
                4. Comment pouvez-vous éviter la collecte de vos Données de navigation?
            </h3>
            <p class="regular-text">
                Vous pouvez en tout temps, à partir de votre navigateur, simplement effacer les
                témoins présents sur vos ordinateurs ou désactiver entièrement la fonctionnalité qui
                conserve ou archive des témoins sur ce site. Certaines fonctionnalités existent
                également afin de vous permettre de désactiver certains témoins et conserver
                uniquement les témoins que vous désirez. Si cela vous intéresse, référez-vous aux
                paramètres de votre navigateur.<br /><br />Si vous choisissez de désactiver ces
                témoins, vous pourrez tout de même accéder à l'information se trouvant sur ce site.
                Cette désactivation fera toutefois en sorte que ce site vous traitera comme un
                nouvel usager à chacune de vos visites et que seules des publicités aléatoires et
                non personnalisées vous seront diffusées.<br /><br />Il se peut toutefois que
                certaines fonctionnalités avancées de ce site soient involontairement affectées,
                certains témoins assurant les interrelations entre les diverses pages de celui-ci.
                Si cette désactivation affecte votre navigation, nous vous invitons à communiquer,
                en tout temps, avec nous par l’entremise de nos coordonnées disponibles sur ce site.
            </p>

            <h3 class="title small">RESPONSABLE DE LA PROTECTION DES DONNÉES PERSONNELLES</h3>
            <p class="regular-text">
                <strong>Jonathan Lee Hickey</strong>, Vice-président principal, affaires juridiques et secrétariat corporatif et responsable de la protection des renseignements personnels : <a href="mailto:vieprivee@quebecor.com">vieprivee@quebecor.com</a>
            </p>
        </section>

        <section class="politique" v-else>
            <h1 class="title">PRIVACY POLICY </h1>
            <p class="regular-text">
                Elmire, (hereinafter, “we”, “us”, “our”) wishes to inform you about the use we make of the information we collect when you visit our website (or our social media platforms or our specialized applications) and when you visit the sites of our affiliated companies containing an hyperlink authorized by us (hereinafter, the “Site”). Accordingly, this Privacy policy (hereinafter, the “Policy”) sets out how we collect, use, disclose and process the information you provide to us when you use the Site.


            </p>
            <h2 class="title medium">PERSONAL INFORMATION </h2>
            <h3 class="title small">
                1. What does “Personal information” (hereinafter “PI”) mean and how is it collected?
            </h3>
            <p class="regular-text">
                PI is information about an identifiable individual. This means information which can be used to identify you. In certain very specific circumstances, namely when you register for our various personalization, email and online promotional contest services, Elmire asks you to enter your first and last name, address, email address and certain other information in a form on the screen.

                Our servers may also collect your computer’s IP address and the name of your Internet service provider. In most cases, such information is not PI but rather Navigation data, and we refer you to the “Navigation data” section below to better understand our policy relating thereto.
            </p>
            <h3 class="title small">2. How is the PI used? </h3>
            <p class="regular-text">
                Collecting PI gives us the ability to provide you with a service more relevant to your needs, and to improve the content, interface and features of the Site based on the information collected, to contact you in the event that you win a prize and, if you informed us that you so desire, to send you occasionally information via email. Such information is also used for monitoring purposes and to improve the use.
            </p>
            <h3 class="title small">3. Is the PI shared with third parties? </h3>
            <p class="regular-text">
                We do not transfer, sell or disclose your PI in any manner whatsoever to any third party, including our advertisers, other clients or partners, without obtaining your prior consent. Notwithstanding the above, your PI may be provided: (i) to Elmire’s subsidiaries and affiliated companies and (ii) to the third party companies hired by Elmire to store or process such information in the name and on behalf of Elmire, pursuant to contractual agreements that ensure the confidentiality and security of such PI. Please note that Elmire may use the services of providers located outside of Canada. As a result, it is possible, in rare cases, that the laws in force in such foreign countries allow PI to be provided to judicial, quasi-judicial or governmental authorities.
            </p>
            <h3 class="title small">4. Do we receive PI from third parties? </h3>
            <p class="regular-text">
                When you use the single sign-on of our social network partners (namely Facebook, Twitter, Yahoo! and Google), we may receive your email address, as well as your public profile information. If you wish to control such information, you must configure your privacy settings on the applicable social networks.
            </p>
            <h3 class="title small">
                5. How is the PI kept and for how long?
            </h3>
            <p class="regular-text">
                The PI collected is saved on servers which are inaccessible to the public and is protected by appropriate security measures. Accordingly, rest assured that security measures have been put in place to protect your data against unauthorized loss, theft, reviewing, copying, use and modification.
                <br><br>
                We will keep the PI only to the extent that it is necessary or relevant for the purposes mentioned in this Policy or as required by law.
            </p>
            <h3 class="title small">6. What are the risks? </h3>
            <p class="regular-text">
            Notwithstanding the above, and despite our good will and the measures we put in place, no data transmission on the Site or generally on Internet is completely guaranteed or entirely risk-free. For this reason, we cannot guarantee the absolute security of your PI and, accordingly, we cannot accept any responsibility for its use or misuse by third parties, its interception during transmission via Internet, or if it is subjected to hacking or fraud.
            <br><br>
            Be vigilant, our Site may contain links to websites managed by third parties; such links are only offered to you for your enjoyment. Activating those links will make you leave the Site. Be aware that the Site and Elmire have no control over the websites of such third parties and that listing such links on the Site does not, in any case, create any liability for Elmire. You should also be aware that certain third parties may collect your data without our knowledge, such as your IP address and your navigation data. We do everything we can to prevent this from happening, but some third party practices remain beyond our control.
            <br><br>
            Furthermore, the voluntary sharing of PI during chat sessions via messaging, on a blog or on any interactive page where you make a public contribution by posting online content that you generate is not covered by this Policy. It is important to be vigilant when you communicate your PI through such means and we encourage you to review our Terms of use. Elmire declines any responsibility in relation thereto. We advise you to always remain cautious and careful when you’re online and navigate the Internet and when you create Internet content, in particular, by adding comments.
            </p>
            <h3 class="title small">7. Can you access your PI? </h3>
            <p class="regular-text">
                Yes, you can access the PI that applies to you or ask that it be rectified by submitting a written request at contact@elmire.ca. We will be able to respond to your request only if we have a personalized file that applies to you (which is the case when you provided, or agree to provide, PI to us).
            </p>
            <h2 class="title medium">NAVIGATION DATA </h2>
            <h3 class="title small">
                1.  What does “Navigation data” mean and how is it collected?
            </h3>
            <p class="regular-text">
                The Navigation data is anonymous data related to your browsing activity or your device.  For example, it includes the data related to your IP address, your browsing history, the content of those pages, the number of clicks you make, your type of device, the site you came from and the site you’re going to when you leave ours, your approximate geographical location, your email, as well as the ads and videos viewed on the site.
                <br><br>
                The Navigation data is collected by using “cookies”, which are small data files that are recorded on the hard drive of your electronic devices when you navigate on this site. Cookies are sent automatically by this site’s server to the browser of your device and are recorded, by default, on its hard drive.
                <br><br>
                The Navigation data is anonymous and, accordingly, is not used by us in order to discover your identity (by matching or any other method). It is only used for the purposes described below.
            </p>
            <h3 class="title small">2.  Who collects the Navigation data?</h3>
            <p class="regular-text">
                The Navigation data is or may be collected, in whole or in part, by (i) us, namely Elmire, its subsidiaries and affiliated companies (hereinafter “us”) and (ii) certain companies with whom we collaborate for commercial or non-commercial purposes (such as our advertising partners, content partners, companies which may acquire some of our assets, or service, solution or technological platform providers associated with this site) (“Partners”).
                <br><br>
                The data which we may collect or which may be collected by our Partners is regarded as “non-sensitive” and is in depersonalized form. In addition, our Partners are legally bound to adhere to the recognized principles governing the protection of personal information and to respect their own privacy protection policies.
            </p>
            <h3 class="title small">3.  How is the Navigation data used? </h3>
            <p class="regular-text">
                This information is collected to allow us:
                <br><br>
                (a) to generate statistical data concerning our level of readership, the performance and use of our sites and their contents and the type of person interested therein;
                <br><br>
                (b) to improve your browsing experience. For example, your browser data [namely the language, time zone and plug-in) will be offered to you, by default, during your subsequent visits on this site, so that you will not need to enter your specific settings repeatedly; and
                <br><br>
                (c) to provide you with ads related to your interests such as, for example, ads related to the sites and contents you viewed.
            </p>
            <h3 class="title small">
                4.  How can you prevent your Navigation data from being collected?
            </h3>
            <p class="regular-text">
                You can simply delete at any time, from your browser, the cookies present on your computer or completely deactivate the feature that keeps or stores cookies on this site. Certain features also allow you to deactivate some cookies and to keep only those you want. If this interests you, review your browser settings.
                <br><br>
                If you choose to deactivate those cookies, you will still be able to access the information on this site.  However, if the cookies are deactivated, this site will treat you like a new user every time you visit and will show you only random and non-personalized ads.
                <br><br>
                Certain advanced features on this site may also be inadvertently affected, since some cookies provide the interconnections between the site’s various pages. If the deactivation affects your browsing, we invite you to contact us, at any time, at our contact details available on this site.
            </p>

            <h3 class="title small">PRIVACY OFFICER</h3>
            <p class="regular-text">
                <strong>Jonathan Lee Hickey</strong>, Senior Vice President, Legal Affairs and Corporate Secretary and Privacy Officer: <a href="mailto:vieprivee@quebecor.com">vieprivee@quebecor.com</a>
            </p>
        </section>
    </div>
</template>

<script>
// Intro Animation Scripts
require('@/plugins/AnimationsLayer')

export default {
    name: 'Politique',
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/02-Tools/_variables.scss';

.politique {
    width: 100%;
    background-color: $color5;
    padding: 8vw 20vw;

    .title {
        margin-bottom: 20px;
    }

    .regular-text {
        margin-bottom: 40px;
        span {
            font-weight: bold;
        }

        a {
            color: $color2;
            text-decoration: underline;
        }
    }
}
</style>
