<template>
    <div class="page-wrapper">
        <img
            src="@/assets/img/publicite-en-ligne.jpg"
            alt="Publicité en ligne"
            class="mobile-hero"
            data-inview="revealRight"
            data-delay="200"
        />
        <section class="service-wrap scroll-fix">
            <div class="left">
                <div class="data intro">
                    <h1 class="title">{{ $t('Publicité en ligne') }}</h1>
                    <p class="regular-text">
                        {{ $t('Offrez-vous un service de publicité clé en main avec des experts qui connaissent le Québec.') }}
                    </p>
                    <a href="#" class="cta" @click.prevent="showform"
                        ><p>{{ $t('Demander une soumission') }}</p></a
                    >
                </div>

                <div id="serv-1" class="data" data-image-index="0">
                    <p class="number">01</p>
                    <h2 class="title small">{{ $t('Campagne publicitaire') }}</h2>
                    <p class="regular-text">
                        {{ $t('Peu importe le problème d’affaires à résoudre, nos stratégies médias numériques 360 sont en mesure de maximiser votre retour sur investissement. On y arrive grâce à notre expertise approfondie des plateformes.') }}
                    </p>
                </div>

                <div id="serv-2" class="data">
                    <p class="number">02</p>
                    <h2 class="title small">{{ $t('Programmatique') }}</h2>
                    <p class="regular-text">
                        {{ $t('Notre collaboration avec Québecor ID, experts en programmatique et en solution d’audience, rend nos possibilités de ciblages infinis. L’accès à des données de qualité supérieure rend nos campagnes plus performantes sans dépendre des géants du web.') }}
                    </p>
                </div>

                <div id="serv-3" class="data" data-image-index="1">
                    <p class="number">03</p>
                    <h2 class="title small">{{ $t('Référencement payant') }}</h2>
                    <p class="regular-text">
                        {{ $t('La publicité sur les moteurs de recherche est un canal d’acquisition incontournable puisqu’il est directement lié à l’intention des utilisateurs. Nos experts certifiés sont spécialisés en publicité sur Google, Bing, l’App Store d’Apple et Google Play.') }}
                    </p>
                </div>

                <div id="serv-4" class="data">
                    <p class="number">04</p>
                    <h2 class="title small">{{ $t('Publicité Facebook') }}</h2>
                    <p class="regular-text">
                        {{ $t('Grâce à nos différents partenaires au sein de Québecor et à l’externe, peu d’agences au Québec peuvent se vanter de gérer un aussi grand nombre de campagnes Facebook en simultanées. Cette plateforme publicitaire n’a plus de secrets pour nous.') }}
                    </p>
                </div>

                <div id="serv-5" class="data" data-image-index="2">
                    <p class="number">05</p>
                    <h2 class="title small">{{ $t('Publicité Instagram') }}</h2>
                    <p class="regular-text">
                        {{ $t('Si votre clientèle est plus jeune, vouloir faire de la publicité sur Instagram est un réflexe naturel. Nous recommandons souvent d’intégrer cette plateforme dans le cadre d’un plan média diversifié afin de rejoindre la cible à différentes étapes de son parcours.') }}
                    </p>
                </div>

                <div id="serv-6" class="data">
                    <p class="number">06</p>
                    <h2 class="title small">{{ $t('Publicité YouTube') }}</h2>
                    <p class="regular-text">
                        {{ $t('Quand vient le temps de lancer une campagne de notoriété, faire de la publicité vidéo sur YouTube est une excellente façon de renforcer l’image de sa marque. Combinez les données de Google avec la richesse de nos données pour dépasser vos objectifs.') }}
                    </p>
                </div>
            </div>
            <div class="right fixed-floating-image">
                <img
                    class="float-img is-active"
                    src="@/assets/img/service-7.jpg"
                    alt=""
                    data-inview="revealRight"
                    data-delay="200"
                />
                <img class="float-img" src="@/assets/img/service-8.jpg" alt="" />
                <img class="float-img" src="@/assets/img/service-9.jpg" alt="" />
            </div>
        </section>

        <section class="autres-services" v-if="$route && $route.meta">
            <a :href="$route.meta.lang === 'fr' ? '/services/conception-web' : '/en/services/web-design'" class="box">
                <div class="bottom">
                    <h3 class="title">{{ $t('Conception web') }}</h3>
                    <img src="@/assets/img/arrow-right.svg" class="arrow" alt="Arrow right" />
                </div>
                <div class="overlay"></div>
                <img src="@/assets/img/site-web-small.jpg" class="main-img" alt="Conception web" />
            </a>
            <a :href="$route.meta.lang === 'fr' ? '/services/referencement' : '/en/services/seo'" class="box">
                <div class="bottom">
                    <h3 class="title">{{ $t('Référencement') }}</h3>
                    <img src="@/assets/img/arrow-right.svg" class="arrow" alt="Arrow right" />
                </div>
                <div class="overlay"></div>
                <img
                    src="@/assets/img/referencement-small.jpg"
                    class="main-img"
                    alt="Référencement"
                />
            </a>
        </section>

        <div class="overlay-form" :class="{ show: form }">
            <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="hideform" />
            <div class="form-wrap">
                <h3 class="title">{{ $t('Demander une soumission') }}</h3>

                <FormSoumission :hideform="hideform" />
            </div>
            <div class="opacity" @click.prevent="hideform"></div>
        </div>
    </div>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import FormSoumission from '@/components/FormSoumission'

gsap.registerPlugin(ScrollTrigger)

// Intro Animation Scripts
require('@/plugins/AnimationsLayer')

export default {
    name: 'Services',

    components: { FormSoumission },

    data() {
        return {
            form: false,
            isSending: false,
            formData: {},
        }
    },

    methods: {
        showform() {
            if (this.form) {
                this.form = false
            } else {
                this.form = true
            }
        },
        hideform() {
            this.form = false
        },
    },

    watch: {
        $route: {
            handler($route) {
                if ($route && $route.hash) {
                    setTimeout(() => {
                        const $zone = document.querySelector($route.hash.replace('a-', ''))
                        const topPos = $zone.getBoundingClientRect().top + window.scrollY - 200
                        // this changes the scrolling behavior to "smooth"
                        window.scroll({
                            top: topPos,
                            behavior: 'smooth',
                        })
                    }, 1175)
                }
            },
            immediate: true,
        },
    },

    mounted() {
        // const scrollFixHeight = document.querySelector('.scroll-fix').offsetHeight
        // const imageFixHeight = document.querySelector('.fixed-floating-image img').offsetHeight
        const floatImgs = [].slice.call(document.querySelectorAll('.float-img'))

        gsap.to('.scroll-fix', {
            scrollTrigger: {
                trigger: '.scroll-fix',
                start: 'top 91',
                end: 'bottom bottom',
                scrub: true,
                pin: '.fixed-floating-image',
                markers: false,
                anticipatePin: 1,
            },
            ease: 'none',
        })

        const loopArray = []
        loopArray.slice
            .call(document.querySelectorAll('[data-image-index]'))
            .forEach(function (elem) {
                gsap.to(elem, {
                    scrollTrigger: {
                        trigger: elem,
                        start: 'top center',
                        markers: false,
                        onEnter: () => {
                            floatImgs.forEach(img => {
                                img.classList.remove('is-active')
                            })
                            floatImgs[elem.getAttribute('data-image-index')].classList.add(
                                'is-active'
                            )
                        },
                        onEnterBack: () => {
                            floatImgs.forEach(img => {
                                img.classList.remove('is-active')
                            })
                            floatImgs[elem.getAttribute('data-image-index')].classList.add(
                                'is-active'
                            )
                        },
                    },
                    ease: 'none',
                })
            })
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/02-Tools/_variables.scss';

.mobile-hero {
    display: none;
    @media only screen and (max-width: 600px) {
        display: block;
    }
}

.service-wrap {
    width: 100%;
    position: relative;

    .left {
        position: relative;
        width: 50%;
        padding: 5vw 10vw;
        background-color: $color5;

        .data {
            margin-top: 15vw;

            &:last-child {
                padding-bottom: 15vw;
            }

            .number {
                color: $color1;
                font-weight: bold;
                font-size: 24px;
                margin-bottom: 20px;
                position: relative;
                z-index: 100;

                &:before {
                    content: '';
                    display: block;
                    width: 80px;
                    height: 5px;
                    background-color: $color3;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translate(0, -50%);
                    z-index: -1;
                    transition: all 0.2s ease;
                }
            }

            .title {
                margin-bottom: 20px;
            }

            &.intro {
                margin-top: 10vw;
                .title {
                    font-size: 30px;
                    margin-bottom: 30px;
                }
                .regular-text {
                    margin-bottom: 30px;
                }
                .cta {
                    &:hover {
                        &:before {
                            width: 240px;
                            height: 240px;
                        }
                    }
                }
            }
        }
    }

    .right {
        width: 50%;
        height: calc(100vh + 25px);
        position: absolute;
        top: -25px;
        right: 0;

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .float-img {
            display: block;
            width: 100%;
            height: 100vh;
            object-fit: cover;

            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            object-fit: cover;

            opacity: 0;

            transition: opacity 0.65s ease 0s;

            &.is-active {
                opacity: 1;
            }
        }
    }

    @media only screen and (max-width: 1000px) {
        .left {
            width: 70%;
        }
        .right {
            width: 30%;
        }
    }
    @media only screen and (max-width: 600px) {
        .left {
            width: 100%;
        }
        .right {
            display: none;
        }
    }
}

.autres-services {
    padding: 5vw;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    position: relative;
    z-index: 1000;

    .box {
        display: block;
        width: calc(50% - 20px);
        position: relative;
        overflow: hidden;

        .main-img {
            transform: scale(1, 1);
            position: relative;
            z-index: 1;
            transition: all 0.4s ease;
        }

        &:hover {
            .main-img {
                transform: scale(1.1, 1.1);
            }
        }

        .overlay {
            width: 100%;
            height: 70%;
            z-index: 10;
            position: absolute;
            left: 0;
            bottom: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
            opacity: 0.7;
        }

        .bottom {
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 100;
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 30px;

            .title {
                color: #fff;
            }

            .arrow {
                width: 30px;
            }
        }
    }

    @media only screen and (max-width: 800px) {
        padding: 10vw 5vw;
        flex-wrap: wrap;

        .box {
            width: 100%;
            margin-bottom: 5vw;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.overlay-form {
    position: relative;
    z-index: -1;
    transition: all 0.2s ease;

    &.show {
        z-index: 99999999;
        .form-wrap {
            transform: translate(-50%, -50%) scale(1, 1);
            opacity: 1;
        }
        .opacity {
            z-index: 1001;
            opacity: 0.8;
        }
        .close {
            display: block;
        }
    }

    .form-wrap {
        position: fixed;
        top: 50%;
        left: 50%;
        z-index: 1500;
        width: 550px;
        padding: 4vw;
        transform: translate(-50%, -50%) scale(0.8, 0.8);
        background-color: #fff;
        text-align: center;
        transition: all 0.2s ease;
        opacity: 0;
    }

    .title {
        margin-bottom: 20px;
    }

    input[type='text'],
    input[type='email'] {
        width: 100%;
        height: 50px;
        border-radius: 3px;
        margin-bottom: 10px;
        border: 0;
        appearance: none;
        background-color: #efefef;
        padding: 10px 20px;
        color: $color1;
        font-family: 'Gotham';
        font-weight: 500;
        font-size: 14px;
    }

    textarea {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        height: 120px;
        min-height: 120px;
        max-height: 120px;
        border-radius: 3px;
        margin-bottom: 10px;
        border: 0;
        appearance: none;
        background-color: #efefef;
        padding: 20px;
        color: $color1;
        font-family: 'Gotham';
        font-weight: 500;
        font-size: 14px;
    }

    input[type='submit'] {
        background-color: transparent;
        font-family: 'Gotham';
        font-weight: 500;
        font-size: 14px;
        color: $color1;

        &:hover {
            background-color: $color3;
        }
    }

    .close {
        width: 30px;
        position: fixed;
        top: 25px;
        right: 25px;
        cursor: pointer;
        z-index: 1500;
        display: none;
        opacity: 1;
    }

    .opacity {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background-color: #000;
        opacity: 0;
        z-index: -1;
        transition: all 0.2s ease;
    }

    @media only screen and (max-width: 600px) {
        .form-wrap {
            width: 330px;
            padding: 8vw;
        }
    }
}
</style>
